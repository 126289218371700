import axios from 'axios';
import react from 'react';
import "./malm.css";

class Malm extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            checked: 0
        }
        this.add_match = this.add_match.bind(this);
        this.update_checked = this.update_checked.bind(this);
    };

    componentDidMount () {
        axios.get('https://civfrcards.me/betting/validate_user')
        .catch((err) => { 
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = ("https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email");
            }
        });
    }

    unauthorized_redirect () {
        this.props.unauthorized_redirect();
    }

    update_checked (e) {
        const CHECKED = this.state.checked ? 0 : 1;
        this.setState({checked: CHECKED});
    }

    add_match (e) {
        e.preventDefault();
        const DATE = document.getElementById("match_creation_date_feedback");
        const TIME = document.getElementById("match_creation_time_feedback");
        const LINK = document.getElementById("match_creation_link_feedback");
        const CATEGORY = document.getElementById("match_creation_category_feedback");
        const TEAM_ONE = document.getElementById("match_creation_team_one_feedback");
        const TEAM_TWO = document.getElementById("match_creation_team_two_feedback");
        DATE.style.display = 'none';
        TIME.style.display = 'none';
        LINK.style.display = 'none';
        TEAM_ONE.style.display = 'none';
        TEAM_TWO.style.display = 'none';
        if (e.target.category.value.match(/^(CWC|Civ FR|CPL|Others)$/) === null) {
            CATEGORY.style.display = 'initial';
            return;
        }
        if (e.target.date.value.match(/^(0[1-9]|1[0,2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/) === null) {
            DATE.style.display = 'initial';
            return;
        }
        if (e.target.time.value.match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/) === null) {
            TIME.style.display = 'initial';
            return;
        }
        if ((e.target.stream_link.value.length > 0) && (e.target.stream_link.value.match(/https:\/\/twitch.tv\/.[a-zA-Z]/) === null)) {
            LINK.style.display = 'initial';
            return;
        }
        if (e.target.team_one.value.length < 1) {
            TEAM_ONE.style.display = 'initial';
            return;
        }
        if (e.target.team_two.value.length < 1) {
            TEAM_TWO.style.display = 'initial';
            return;
        }
        axios.post('https://civfrcards.me/betting/add_new_match', {
            team_one: e.target.team_one.value,
            team_two: e.target.team_two.value,
            date: e.target.date.value,
            time: e.target.time.value,
            link: e.target.stream_link.value,
            category: e.target.category.value,
            match_confirmed: this.state.checked
        })
        .then(() => {
            this.props.send_feedback({type: 'success', message: localStorage.getItem("civfr_lang") === 'FR' ? "Succès" : "Success!"})
            document.getElementById("match_form").reset();
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = ("https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email");
            }
        })
    }
    
    render () {
        return (
            <div id="adminPage">
                <h4>{localStorage.getItem("civfr_lang") === 'FR' ? "La Page du Boss" : "The Boss Page"}</h4>
                <form id="match_form" onSubmit={this.add_match}>
                    <select id="match_form_category" name="category" defaultValue={"default"} required>
                        <option value="default" disabled>{localStorage.getItem("civfr_lang") === 'FR' ? "Selectionner categorie du match" : "Select match category"}</option>
                        <option value="CWC">CWC</option>
                        <option value="Civ FR">Civ FR</option>
                        <option value="CPL">CPL</option>
                        <option value="Others">Others</option>
                    </select>
                    <input id="match_form_date" name="date" type="text" placeholder="mm/dd/yyyy" required></input>
                    <span required id="match_form_date_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Format incorrect, veuillez indiquer une date sous la forme de mm/dd/yyyy" : "Incorrect format, please provide a date in the form of mm/dd/yyyy"}</span>
                    <label id="label_for_match_form_time" htmlFor="match_form_time">{localStorage.getItem("civfr_lang") === 'FR' ? "Heure du match (GMT):" : "Match time (GMT):" }</label>
                    <input required id="match_form_time" name="time" type="text" placeholder="hh:mm"/>
                    <label for="match_confirmed" id="match_confirmed_label">{localStorage.getItem("civfr_lang") === 'FR' ? "Date et heure confirmées?" : "Date & Time confirmed?"}</label>
                    <input type="checkbox" id="match_confirmed" name="match_confirmed" checked={this.state.checked ? true : false} onClick={this.update_checked}></input>
                    <span id="match_form_time_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Format incorrect, veuillez indiquer une heure au format hh:mm" : "Incorrect format, please provide a time in the format of hh:mm"}</span>
                    <div id="team_names">
                        <input className="match_form_team_name" name="team_one" type="text" required placeholder={localStorage.getItem("civfr_lang") === 'FR' ? "Nom equipe 1" : "Team name 1"}/>
                        <input className="match_form_team_name team_two_form_input" name="team_two" type="text" required placeholder={localStorage.getItem("civfr_lang") === 'FR' ? "Nom equipe 2" : "Team name 2"}/>
                    </div>
                    <span>{localStorage.getItem("civfr_lang") === 'FR' ? "Merci de remplir les champs requis" : "Please fill out the required fields"}</span>
                    <input id="form_stream_link" name="stream_link" type="text" placeholder={localStorage.getItem("civfr_lang") === 'FR' ? "Lien de stream, le cas echeant" : "Stream link, if applicable"}/>
                    <span id="match_form_link_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez fournir un lien de flux valide" : "Please provide a valid stream link: https://twitch.tv/channel"}</span>
                    <button type="submit" id="match_form_submit">{localStorage.getItem("civfr_lang") === 'FR' ? "VALIDER LE NOUVEAU MATCH" : "VALIDATE THE NEW MATCH"}</button>
                </form>
                <span className="form_feedback_error" id="match_creation_date_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Format incorrect, veuillez indiquer une date sous la forme de mm/dd/yyyy" : "Incorrect format, please provide a date in the form of mm/dd/yyyy"}</span>
                <span className="form_feedback_error" id="match_creation_time_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Format incorrect, veuillez indiquer une heure au format hh:mm" : "Incorrect format, please provide a time in the format of hh:mm"}</span>
                <span className="form_feedback_error" id="match_creation_link_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez fournir un lien de flux valide" : "Please provide a valid stream link: https://twitch.tv/channel"}</span>
                <span className="form_feedback_error" id="match_creation_team_one_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez fournir un nom pour la première équipe" : 'Please provide a name for team one'}</span>
                <span className="form_feedback_error" id="match_creation_team_two_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez fournir un nom pour l'équipe deux" : 'Please provide a name for team two'}</span>
                <span className="form_feedback_error" id="match_creation_category_feedback" style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez fournir une catégorie" : 'Please provide a category'}</span>
            </div>
        );
    };
};

export default Malm;